import './styles.css'
import { useRef, useState } from 'react'

import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import HTMLFlipBook from 'react-pageflip'
import Page1 from '../../assets/page1.jpg'
import Page2 from '../../assets/page2.jpg'
import Page3 from '../../assets/page3.jpg'
import Page4 from '../../assets/page4.jpg'
import Page5 from '../../assets/page5.jpg'
import Page6 from '../../assets/page6.jpg'
import Page7 from '../../assets/page7.jpg'
import Page8 from '../../assets/page8.jpg'
import Page9  from '../../assets/page9.jpg'
import Page10 from '../../assets/page10.jpg'
import Page11 from '../../assets/page11.jpg'
import Page12 from '../../assets/page12.jpg'
import Page13 from '../../assets/page13.jpg'
import Page14 from '../../assets/page14.jpg'
import Page15 from '../../assets/page15.jpg'
import Page16 from '../../assets/page16.jpg'
import Page17 from '../../assets/page17.jpg'
import Page18 from '../../assets/page18.jpg'
import Page19 from '../../assets/page19.jpg'
import Page20 from '../../assets/page20.jpg'
import Page21 from '../../assets/page21.jpg'
import Page22 from '../../assets/page22.jpg'
import Page23 from '../../assets/page23.jpg'
import Page24 from '../../assets/page24.jpg'
import Page25 from '../../assets/page25.jpg'
import Page26 from '../../assets/page26.jpg'
import Page27 from '../../assets/page27.jpg'
import Page28 from '../../assets/page28.jpg'
import Page29 from '../../assets/page29.jpg'
import Page30 from '../../assets/page30.jpg'
import Page31 from '../../assets/page31.jpg'
import Page32 from '../../assets/page32.jpg'
import Page33 from '../../assets/page33.jpg'
import Page34 from '../../assets/page34.jpg'
import Page35 from '../../assets/page35.jpg'
import Page36 from '../../assets/page36.jpg'
import Page37 from '../../assets/page37.jpg'
import Page38 from '../../assets/page38.jpg'
import Page39 from '../../assets/page39.jpg'

type Product = {
  title: string,
  link: string
}
type PageType = {
  page: number,
  products: Product[]
}
const pageInfo:PageType[] = [
  {
    page: 34,
    products: [
      {
        title: '74.481 tan',
        link: 'https://www.lazamani.nl/products/74-481-t'
      }
    ]
  },
  {
    page: 36,
    products: [
      {
        title: '74.482 ice',
        link: 'https://www.lazamani.nl/products/74-482-i'
      }
    ]
  },
  {
    page: 37,
    products: [
      {
        title: '74.478 camel',
        link: 'https://www.lazamani.nl/products/74-478-c'
      }
    ]
  },
  {
    page: 38,
    products: [
      {
        title: '74.478 ice',
        link: 'https://www.lazamani.nl/products/74-478-i'
      }
    ]
  },
  {
    page: 1,
    products: [
      {
        title: '57.318 taupe',
        link: 'https://www.lazamani.nl/products/57-318-t'
      }
    ]
  },
  {
    page: 3,
    products: [
      {
        title: '55.079 taupe',
        link: 'https://www.lazamani.nl/products/55-079-t'
      },
      {
        title: '55.077 cuoio',
        link: 'https://www.lazamani.nl/products/55-077-c'
      }
    ]
  },
  {
    page: 5,
    products: [
      {
        title: '55.069 ecru',
        link: 'https://www.lazamani.nl/products/55-069-e'
      }
    ]
  },
  {
    page: 7,
    products: [
      {
        title: '55.067 black',
        link: 'https://www.lazamani.nl/products/55-067-b'
      },
      {
        title: '55.066 ecru',
        link: 'https://www.lazamani.nl/products/55-066-e'
      }
    ]
  },
  {
    page: 10,
    products: [
      {
        title: '85.341 pewter',
        link: 'https://www.lazamani.nl/products/85-341-p'
      }
    ]
  },
  {
    page: 11,
    products: [
      {
        title: '68.033 green',
        link: 'https://www.lazamani.nl/products/68-033-g'
      },
      {
        title: '68.039 taupe',
        link: 'https://www.lazamani.nl/products/68-039-t'
      }
    ]
  },
  {
    page: 12,
    products: [
      {
        title: '68.039 black',
        link: 'https://www.lazamani.nl/products/68-039-b'
      }
    ]
  },
  {
    page: 21,
    products: [
      {
        title: '68.040 black',
        link: 'https://www.lazamani.nl/products/68-040-b'
      },
      {
        title: '68.040 green',
        link: 'https://www.lazamani.nl/products/68-040-g'
      },
      {
        title: '68.040 taupe',
        link: 'https://www.lazamani.nl/products/68-040-t'
      }
    ]
  },
  {
    page: 13,
    products: [
      {
        title: '67.050 taupe',
        link: 'https://www.lazamani.nl/products/67-050-t'
      },
      {
        title: '68.001 cognac',
        link: 'https://www.lazamani.nl/products/68-001-c'
      },
      {
        title: '68.001 green',
        link: 'https://www.lazamani.nl/products/68-001-gr'
      },
      {
        title: '67.050 black',
        link: 'https://www.lazamani.nl/products/67-050-b'
      },
      {
        title: '68.001 grey',
        link: 'https://www.lazamani.nl/products/68-001-g'
      }
    ]
  },
  {
    page: 14,
    products: [
      {
        title: '85.347 fango',
        link: 'https://www.lazamani.nl/products/85-347-f'
      }
    ]
  },
  {
    page: 20,
    products: [
      {
        title: '85.346 beige',
        link: 'https://www.lazamani.nl/products/85-346-b'
      },
      {
        title: '85.346 tan',
        link: 'https://www.lazamani.nl/products/85-346-t'
      }
    ]
  },
  {
    page: 16,
    products: [
      {
        title: '55.078 brown',
        link: 'https://www.lazamani.nl/products/55-078-b'
      }
    ]
  },
  {
    page: 17,
    products: [
      {
        title: '67.031 grey',
        link: 'https://www.lazamani.nl/products/67-031-g'
      }
    ]
  },
  {
    page: 18,
    products: [
      {
        title: '67.029 bronze',
        link: 'https://www.lazamani.nl/products/67-029-br'
      }
    ]
  },
  {
    page: 22,
    products: [
      {
        title: '55.051 taupe',
        link: 'https://www.lazamani.nl/products/55-051-t'
      },
      {
        title: '55.051 cuoio',
        link: 'https://www.lazamani.nl/products/55-051-c'
      },
      {
        title: '55.051 miele',
        link: 'https://www.lazamani.nl/products/55-051-m'
      },
      {
        title: '55.051 ghiaccio',
        link: 'https://www.lazamani.nl/products/55-051-g'
      }
    ]
  },
  {
    page: 23,
    products: [
      {
        title: '85.363 black',
        link: 'https://www.lazamani.nl/products/85-363-b'
      }
    ]
  },
  {
    page: 26,
    products: [
      {
        title: '85.349 cream',
        link: 'https://www.lazamani.nl/products/85-349-cream'
      },
      {
        title: '85.349 black',
        link: 'https://www.lazamani.nl/products/85-349-b'
      }
    ]
  },
  {
    page: 27,
    products: [
      {
        title: '85.364 black',
        link: 'https://www.lazamani.nl/products/85-364-b'
      }
    ]
  },
  {
    page: 28,
    products: [
      {
        title: '85.363 black',
        link: 'https://www.lazamani.nl/products/85-363-b'
      },
      {
        title: '85.342 black',
        link: 'https://www.lazamani.nl/products/85-342-b'
      }
    ]
  },
  {
    page: 30,
    products: [
      {
        title: '57.317 black',
        link: 'https://www.lazamani.nl/products/57-317-bl'
      },
      {
        title: '57.317 off white',
        link: 'https://www.lazamani.nl/products/57-317-o'
      }
    ]
  },
  {
    page: 31,
    products: [
      {
        title: '31.211 red',
        link: 'https://www.lazamani.nl/products/31-211-r'
      }
    ]
  },
  {
    page: 32,
    products: [
      {
        title: '67.037 black',
        link: 'https://www.lazamani.nl/products/67-037-b'
      }
    ]
  }
]
const pages = [Page1, Page8, Page9, Page10, Page11, Page12, Page13, Page14, Page15, Page16, Page17, Page18, Page19, Page20, Page21, Page22, Page23, Page24, Page25, Page26, Page27, Page28, Page29, Page30, Page31, Page32, Page33, Page34, Page35, Page36, Page37, Page38, Page39, Page2, Page3, Page4, Page5, Page6, Page7]
const Flipbook = () => {
  const bookRef = useRef<any>()
  const [pageNum, setPageNum] = useState(0)
  const mobile = useMediaQuery('(max-width: 768px)')

  const getProducts = (page: number) => {
    var result: Product[] = [] as Product[]
    if (mobile) {
      return pageInfo.find(element => element.page === page)?.products;
    }
    const left = pageInfo.find(element => element.page === page)?.products;
    const right = pageInfo.find(element => element.page === page + 1)?.products
    left?.map(item => result.push(item))
    right?.map(item => result.push(item))
    return result
  }

  const onFlip = (e: any) => {
    setPageNum(e.data)
  }

  return (
    <div className="flipbook-container">
      {mobile?     <HTMLFlipBook style={{ }} onFlip={onFlip} width={360} height={510} showCover={true} maxShadowOpacity={0.5} drawShadow={true} className='' ref={bookRef}>
        {pages.map((page,index) => {
          return (
            <img src={page} key={index} style={{ height: '100%' }} alt={`Page ${index+1}`} />
          )
        })}
        <div className='demoPage'></div>
      </HTMLFlipBook>: <div className="withButtons">
            <span className='nav-button' onClick={() => { bookRef.current?.pageFlip().flipPrev(); }} ><NavigateBeforeIcon /></span>
      <HTMLFlipBook style={{ transform: pageNum === 0 ? 'translateX(-25%)':'', transition: 'all .5s ease' }} onFlip={onFlip} width={420} height={595} showCover={true} maxShadowOpacity={0.5} drawShadow={true} className='' ref={bookRef}>
        {pages.map((page,index) => {
          return (
            <img src={page} key={index} style={{ height: '100%' }} alt={`Page ${index+1}`} />
          )
        })}
        <div className='demoPage'></div>
      </HTMLFlipBook>

      <span className='nav-button' onClick={() => { bookRef.current?.pageFlip().flipNext(); }} ><NavigateNextIcon /></span>
      </div>}
      <div className="on-this-page">
        {
          pageNum === 0 ? mobile?<>Tap the corners to experience the Fall/Winter 2021 collection</>:<>Turn page to experience the Fall/Winter 2021 collection</>:<></>
        }
        { pageNum !== 0 && pageNum !== 39 ?  getProducts(pageNum)?<>Items on this {mobile?'page':'spread'}: {getProducts(pageNum)?.map((product, index, products) => {
          return (
            <span key={index}>
              <a href={product.link} target='_blank' rel="noreferrer">{product.title}</a>{index < products.length - 1?<>,&nbsp;</>:''}
            </span>
          )
        })}</>:<></>:<></>}
        {
          pageNum === 39 ? <>Fall / Winter 2021 collection, click <a href="https://www.lazamani.nl/collections/winter" target='_blank' style={{ textTransform: 'none' }}>here</a></>:<></>
        }
      </div>
    </div>
  )
}

export default Flipbook