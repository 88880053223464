import Logo from "../../images/nakatori-logo.png"
import './styles.css'

const words = [
  'NAKATORI', 
  'e-commerce experts',
  'passionate', 
  'leaders',
  'energetic', 
  'talented', 
  'creators', 
  'advanced', 
  'forward', 
  'agents', 
  'ambitious', 
  'designers', 
  'dreamers', 
  'confident', 
  'experienced', 
  'collected', 
  'analysts', 
  'developers', 
  'proud', 
  'excited', 
  'operators',  
  'creative', 
  'programmers', 
  'fighters', 
  'authors', 
  'earnest', 
  'loving', 
  'elegant', 
  'intelligent', 
  'experts', 
  'critical', 
  'calm', 
  'thinkers', 
  'brave', 
  'skilled', 
  'coders', 
  'artistic', 
  'progressive', 
  'capable',
  'dedicated',
  'Shopify partners'
]

const Slotmachine = () => {
  return (
    <div className="slots">
      <div className="content">
        <div className="left">
          <div className="top-left">
            <h1>We are</h1>            
          </div>
          <img src={Logo} alt="Nakatori Logo" className="logo"/>
        </div>
        <div className="right-side">
          <div className="slotmachine">
          <div className="hide-slot">
            <div className="slot-container">
                {words.map((word, index) => {
                  return <div className="slot-word" key={index}><h1>{word}</h1></div>
                })}
            </div>
            </div>
          </div>
          <div className="subtitle">
              <h2 className="typewriter">
                creative agency // shopify experts
              </h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slotmachine