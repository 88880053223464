import './styles.css'
import Slotmachine from '../Slotmachine'

const Landing = () => {
  return (
    <div className="landing">
      <Slotmachine />
    </div>
  )
}

export default Landing