import { BrowserRouter as Router, Route } from 'react-router-dom'
import Flipbook from './components/Flipbook/Flipbook'
import Landing from './components/Landing'

const App = () => {
  return (
    <div className="App">
      <Router>
        <Route path='/' exact component={Landing} />
        <Route path='/lazamani' component={Flipbook} />
      </Router>
    </div>
  );
}

export default App
